import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row g-5 g-xl-8"
}
const _hoisted_2 = { class: "row g-5 g-xl-8" }
const _hoisted_3 = { class: "card card-xl-stretch mb-xl-8" }
const _hoisted_4 = { class: "card-body pt-5" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "d-flex align-items-center me-2" }
const _hoisted_7 = { class: "flex-grow-1" }
const _hoisted_8 = { class: "d-flex align-items-center fs-2 fw-bolder flex-wrap" }
const _hoisted_9 = {
  key: 1,
  class: "row g-5 g-xl-8"
}
const _hoisted_10 = {
  key: 0,
  class: "row g-5 g-xl-8"
}
const _hoisted_11 = { class: "floating-progress" }
const _hoisted_12 = { class: "percentage-label" }
const _hoisted_13 = { class: "card card-xl-stretch mb-xl-8" }
const _hoisted_14 = { class: "card-header border-0 pt-5" }
const _hoisted_15 = { class: "card-title align-items-start flex-column" }
const _hoisted_16 = { class: "card-label fw-bolder text-dark" }
const _hoisted_17 = { class: "card-body pt-5" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "d-flex align-items-center me-2" }
const _hoisted_20 = { class: "flex-grow-1" }
const _hoisted_21 = { class: "d-flex align-items-center fs-2 fw-bolder flex-wrap" }
const _hoisted_22 = { class: "d-flex flex-stack pt-10" }
const _hoisted_23 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_ctx.isShowExample)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.papiKostickExampleCache, (item2, index2) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index2,
              class: "col-xl-12"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"image-input image-input-outline\" data-kt-image-input=\"true\" style=\"background-image:url(media/avatars/blank.png);\" data-v-3853c232><div class=\"image-input-wrapper\" style=\"background-image:url(media/papi/contoh.jpg);width:700px;height:900px;background-size:contain;background-repeat:no-repeat;background-position:center;\" data-v-3853c232></div></div><div class=\"card-header border-0 pt-5\" data-v-3853c232><h3 class=\"card-title align-items-start flex-column\" data-v-3853c232><span class=\"card-label fw-bolder text-dark\" data-v-3853c232>Contoh Soal (Pilih Salah Satu yang Paling Mendekati Gambaran Diri Anda)</span></h3></div>", 2)),
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.items, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      onClick: ($event: any) => (_ctx.handleChange(item, true)),
                      class: _normalizeClass(["nav-link btn btn-outline btn-outline-default btn-color-dark d-flex flex-stack text-start p-6 btn-active btn-active-primary", [
                      index !== item2.items.length - 1 && 'mb-6',
                      item.isActive && 'active',
                    ]])
                    }, [
                      _createElementVNode("div", _hoisted_6, [
                        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "form-check form-check-custom form-check-solid form-check-success me-6" }, null, -1)),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("h2", _hoisted_8, _toDisplayString(item.QuestionDescription), 1)
                        ])
                      ])
                    ], 10, _hoisted_5))
                  }), 128))
                ])
              ])
            ]))
          }), 128))
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-lg btn-primary me-3",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openQuestion()))
        }, "Mulai")
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_9, [
        (_ctx.is_ended == false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_el_progress, {
                  percentage: (_ctx.current_time/_ctx.max_time)*100,
                  type: "dashboard",
                  status: "success"
                }, {
                  default: _withCtx(({}) => [
                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "percentage-value" }, null, -1)),
                    _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.formattedTime()), 1)
                  ]),
                  _: 1
                }, 8, ["percentage"])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.papiKostickCache, (item2, index2) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index2,
                  class: "col-xl-12"
                }, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("h3", _hoisted_15, [
                        _createElementVNode("span", _hoisted_16, "No. " + _toDisplayString(item2.Numb), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.items, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          onClick: ($event: any) => (_ctx.handleChange(item, false)),
                          class: _normalizeClass(["nav-link btn btn-outline btn-outline-default btn-color-dark d-flex flex-stack text-start p-6 btn-active btn-active-primary", [
                      index !== item2.items.length - 1 && 'mb-6',
                      item.isActive && 'active',
                    ]])
                        }, [
                          _createElementVNode("div", _hoisted_19, [
                            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "form-check form-check-custom form-check-solid form-check-success me-6" }, null, -1)),
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("h2", _hoisted_21, _toDisplayString(item.QuestionDescription), 1)
                            ])
                          ])
                        ], 10, _hoisted_18))
                      }), 128))
                    ])
                  ])
                ]))
              }), 128)),
              _createElementVNode("div", _hoisted_22, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "mr-2" }, null, -1)),
                _createElementVNode("div", null, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-lg btn-primary me-3",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToEnd()))
                  }, "Selesai")
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_23, _cache[7] || (_cache[7] = [
              _createStaticVNode("<div class=\"row g-5 g-xl-8\" data-v-3853c232><div class=\"row mb-6\" data-v-3853c232><div class=\"col-lg-8\" data-v-3853c232><div class=\"image-input image-input-outline\" data-kt-image-input=\"true\" style=\"background-image:url(media/avatars/blank.png);\" data-v-3853c232><div class=\"image-input-wrapper w-800px h-850px\" style=\"background-image:url(media/misc/ty.jpg);\" data-v-3853c232></div></div></div></div></div>", 1)
            ])))
      ]))
}